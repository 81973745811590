<template>
    <el-collapse v-model="activeName" accordion>
        <el-collapse-item title="Trebuie sa tin cont de referinta / sku / identificator unic?" name="1">
            <div>Pentru a sincroniza corect produsele nu trebuie sa schimbati identificatorul unic. Pentru prestashop acest identificator unic este referinta.</div>
            <div>Daca veti schimba acest atribut la produs, actualizarile nu vor mai functiona si se va importa ca un produs nou.</div>
        </el-collapse-item>
        <el-collapse-item title="Ce sunt Maparile de categorii si caracteristici?" name="2">
            <div>Fiecare magazin online are categorii (ex: accesorii, telefoane) si caracteristici (filtre, atribute, ex: culoare, material).</div>
            <div>Aceste categorii si caracteristici trebuiesc mapate pentru a putea importa corect produsele.</div>
            <div>Categoria pentru Prestashop care va fi luata in calcul este doar cea <span style="color: #00a3d4">principala</span></div>
            <img src="https://api-mp.cel.ro/market_cdn/images/presta16_categorie_principala.png" alt="" width="100%">
            <img src="https://api-mp.cel.ro/market_cdn/images/presta17_categorie_principala.png" alt="">
            <img src="https://api-mp.cel.ro/market_cdn/images/celm_mapare_categorii.png" alt="" width="100%">
        </el-collapse-item>
        <el-collapse-item title="Cum se vor sincroniza produsele?" name="3">
            <div>Daca ati mapat corect corect categoriile si caracteristicile, acestea se vor importa automat.</div>
        </el-collapse-item>
        <el-collapse-item title="Cum activez sincronizarea comenzilor?" name="4">
            <div>Sincronizarea comenzilor se poate activa din tab-ul "Settings"</div>
        </el-collapse-item>
        <el-collapse-item title="Ce inseamna 'Inverval sincronizare comenzi'?" name="5">
            <div>Aceasta setare determina cat de des vom citi comenzile din magazinul dumneavoastra pentru a le sincroniza cu cel.ro si invers.</div>
            <div>Setati un interval mai mare daca aveti un host cu performante mai mici.</div>
        </el-collapse-item>
        <el-collapse-item title="De ce trebuie sa mapez statusurile comenzilor? Am mai multe statusuri in magazinul meu, ce fac in acest caz?" name="6">
            <div>Statusurile comenzilor trebuiesc mapate, deoarece noi vom crea o comanda noua in magazinul dumneavoastra si trebuie sa specificam corect starea comenzii.</div>
            <div>Pentru comenzile create din cel Marketplace, va rugam folositi doar statusurile mapate.</div>
        </el-collapse-item>
        <el-collapse-item title="Ce sunt metodele de plata?" name="7">
            <div>Fiecare comanda are o metoda de plata alocata (plata ramburs, plata cu cardul).</div>
            <div>Create metodele de plata pentru magazinul dumneavoastra sau puteti sa le folositi pe cele detectate (unde este cazul).</div>
        </el-collapse-item>
        <Versions />
    </el-collapse>
</template>

<script>
    import Versions from '@/views/integrari/magazin/shops/shared/Tabs/Info/Versions';

    export default {
        data() {
            return {
                activeName: '1'
            };
        },
        components: {
            Versions
        }
    };
</script>